import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { profileImage } from '@pharmaplan/common';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useUser from '../../../../hooks/useUser';
import styles from './styles';

const GroupChannelListTitle = () => {
  const avatar = useAppSelector(profileImage);
  const { name = '' } = useUser();

  return (
    <Box sx={styles.container}>
      <Avatar src={avatar} sx={styles.avatar} imgProps={{ sx: styles.img }} />
      <Typography sx={styles.name}>
        {name}
      </Typography>
    </Box>
  );
};

export default GroupChannelListTitle;
