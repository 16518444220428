import httpClient from './httpClient';
import { TypeOfUser } from '../helpers/Constants';
import Routes from '../helpers/Routes';
import { ISendbirdGroupAddMetadata } from '../models/ChatModels';

const {
  chat: { channels, channel, chats, sendbirdBasePath, metadata, unreadCount },
  pharmacy: { basePath: pharmacyBasePath },
  user: { userBasePath, prelogout },
} = Routes;

class ChatController {
  adminBasePath: string;
  sendbirdBasePath: string;

  constructor() {
    this.adminBasePath = Routes.admin.adminBasePath;
    this.sendbirdBasePath = sendbirdBasePath;
  }

  createGroupChannel = async (
    userType: Omit<
      TypeOfUser,
      TypeOfUser.admin | TypeOfUser.pharmacyAdmin | TypeOfUser.superAdmin
    >
  ) => httpClient.get(`/${userType}${channel}`);

  groupChannelList = async () =>
    httpClient.get(`${this.adminBasePath}${chats}`);

  adminCreateGroupChannel = async (userId: string) =>
    httpClient.get(`${this.adminBasePath}${channel}/${userId}`);

  addGroupMetadata = async (params: ISendbirdGroupAddMetadata) =>
    httpClient.post(`${this.sendbirdBasePath}${channels}${metadata}`, params);

  clientGetUnread = async (
    userType: Omit<
      TypeOfUser,
      TypeOfUser.admin | TypeOfUser.pharmacyAdmin | TypeOfUser.superAdmin
    >
  ) => httpClient.get(`/${userType}${unreadCount}`);

  adminGetUnread = async () =>
    httpClient.get(`${this.adminBasePath}${unreadCount}`);

  adminCloseChat = async (groupUrl: string) =>
    httpClient.delete(`${this.sendbirdBasePath}${chats}/${groupUrl}`);

  chatPreLogout = async (groupUrls: Array<string>) =>
    httpClient.post(`${userBasePath}${prelogout}`, {
      channels: groupUrls,
    });
}

export default new ChatController();
