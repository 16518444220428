import { createReducer } from '@reduxjs/toolkit';
import {
  addAssignedChannels,
  adminUnreadCount,
  clientUnreadCount,
  createGroupChannel,
  getGroupChannelList,
  logout,
} from '../actions';
import { IChatGroupChannelList } from '../models/ChatModels';

export interface IChatReducerState {
  channelUrl: string;
  channelList: Array<IChatGroupChannelList>;
  clientUnreadCount: number;
  adminUnreadCount: number;
  assignedChannels: Array<string>;
}

export const chatInitialState: IChatReducerState = {
  channelUrl: '',
  channelList: [],
  clientUnreadCount: 0,
  adminUnreadCount: 0,
  assignedChannels: [],
};

const chatReducer = createReducer(chatInitialState, (builder) =>
  builder
    .addCase(createGroupChannel.fulfilled, (state, action) => {
      state.channelUrl = action.payload;
    })
    .addCase(clientUnreadCount.fulfilled, (state, action) => {
      state.clientUnreadCount = action.payload;
    })
    .addCase(adminUnreadCount.fulfilled, (state, action) => {
      state.adminUnreadCount = action.payload;
    })
    .addCase(addAssignedChannels, (state, action) => {
      state.assignedChannels = action.payload;
    })
    .addCase(getGroupChannelList.fulfilled, (state, action) => {
      state.channelList = action.payload;
    })
    .addCase(logout.fulfilled, (state, action) => {
      return chatInitialState;
    })
);

export default chatReducer;
