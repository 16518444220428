import { Box } from '@mui/system';
import { adminUnreadCountSelector } from '@pharmaplan/common';
import React from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import styles from './styles';

const ChatCount = () => {
  const adminUnreadCount = useAppSelector(adminUnreadCountSelector);

  if (!adminUnreadCount) {
    return null;
  }

  return <Box sx={[styles.shakingCount, styles.count]}>{adminUnreadCount}</Box>;
};

export default ChatCount;
