import React, { FC, useEffect } from 'react';

// eslint-disable-next-line import/no-unresolved
import { GroupChannelListItemBasicProps } from '@sendbird/uikit-react/types/modules/GroupChannelList/components/GroupChannelListItem/GroupChannelListItemView';
import { Box, Tooltip, Typography } from '@mui/material';

import {
  addAssignedChannels,
  chatActions,
  ChatStatus,
  getAssignedChannels,
  successSelector,
  userIdSelector,
} from '@pharmaplan/common';

import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import GroupChannelHandler from '@sendbird/uikit-react/handlers/GroupChannelHandler';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import strings from '../../../../localization';
import styles from './styles';

interface GroupChannelItemProps extends GroupChannelListItemBasicProps {}
interface IGroupChannelPreview {
  channelData: any;
  props: GroupChannelItemProps;
  setChannelUrl: any;
}

const GroupChannelPreview: FC<IGroupChannelPreview> = ({
  props,
  setChannelUrl,
  channelData,
}) => {
  const dispatch = useAppDispatch();

  const store = useSendbirdStateContext();
  const sdk = store?.stores?.sdkStore?.sdk;
  const { channel, isSelected } = props ?? {};

  const userId = useAppSelector(userIdSelector);
  const assignedChannels = useAppSelector(getAssignedChannels);
  const metadataSuccess = useAppSelector((state) =>
    successSelector([chatActions.addGroupChannelMetadata], state));

  const { lastMessage, coverUrl: cover, name, url } = channel ?? {};
  const metadata = channelData[url] ?? {};
  const { users } = metadata ?? {};

  const { message, sender } = (lastMessage as any) ?? {};
  const { userId: senderId } = sender ?? {};
  const isMe = senderId === userId;

  const statusHandler = () => {
    if (!users) return ChatStatus.available;
    if (users === userId) return ChatStatus.assignedToMe;
    if (users !== userId) return ChatStatus.engaged;

    return ChatStatus.available;
  };

  const statusConfig = {
    [ChatStatus.engaged]: {
      style: styles.engaged,
      label: strings.engaged,
    },
    [ChatStatus.available]: {
      style: styles.available,
      label: strings.available,
    },
    [ChatStatus.assignedToMe]: {
      style: styles.assignedToMe,
      label: strings.assignedToMe,
    },
    [ChatStatus.away]: {
      style: styles.assignedToMe,
      label: strings.away,
    },
  };

  const { label, style } = statusConfig[statusHandler()];

  useEffect(() => {
    const handleMeta = async () => {
      const channelInstance = await sdk?.groupChannel?.getChannelWithoutCache(
        url,
      );

      channelInstance.getAllMetaData().then((res) => {
        setChannelUrl((prev: any) =>
          ({ ...prev, [url]: res }));
      });
    };
    handleMeta();
  }, [sdk, metadataSuccess]);

  useEffect(() => {
    if (users === userId) {
      dispatch(addAssignedChannels([...assignedChannels, url]));
    }
  }, [users]);

  return (
    <Box
      sx={[
        styles.channelItemContainer,
        isSelected ? styles.selectedChannel : {},
      ]}
    >
      <Box sx={styles.statusContainer}>
        <img style={styles.avatar} src={cover} alt="cover" />
        <Tooltip title={label}>
          <Box sx={[style, styles.dot]} />
        </Tooltip>
      </Box>
      <Box sx={[styles.channelInfoContainer]}>
        <Typography sx={styles.channelTitle}>{name}</Typography>
        <Typography sx={styles.lastMessage}>
          {isMe ? 'You: ' : ''}
          {message || ''}
        </Typography>
      </Box>
    </Box>
  );
};

export default GroupChannelPreview;
