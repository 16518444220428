/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';

import { GroupChannelListHeader } from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader';
import GroupChannelHeader from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import Icon, { IconTypes } from '@sendbird/uikit-react/ui/Icon';
import '@sendbird/uikit-react/dist/index.css';
import { Box } from '@mui/system';

import {
  getGroupChannelList,
  HelpUserTypes,
  userIdSelector,
} from '@pharmaplan/common';

import useUserProfileLink from '../../../hooks/Admin/useUserProfileLink';
import ChatManager from '../../../hooks/Admin/Chat/ChatManager';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import GroupChannelListTitle from './GroupChannelListTitle';
import { setDialog } from '../../../reducers/dialogReducer';
import GroupChannelPreview from './GroupChannelPreview';

import GroupChannelTitle from './GroupChannelTitle';
import CreateGroupDialog from './CreateGroupDialog';
import CustomMessage from './CustomMessage';
import styles from './styles';

const ChatWindow = () => {
  const dispatch = useAppDispatch();

  const [firstMessage, setFirstMessage] = useState(false);

  const { assignToCurrentUser } = ChatManager();

  const [channelUrl, setChannelUrl] = useState({
    url: '',
    channelName: '',
    personnelId: '',
    userType: '',
  });

  const [channelMetadata, setChannelMetadata] = useState<{
    [key: string]: {
      users: string;
      user_type: string;
      personnelId: string;
    };
  }>({});

  const userId = useAppSelector(userIdSelector);
  const { goToUserProfile } = useUserProfileLink();

  const stub = () => {
    console.log('');
  };

  const showm = () => {
    dispatch(
      setDialog({
        showCloseButton: true,
        heading: {
          title: 'Create Chat',
        },
        maxWidth: 'sm',
        Component: <CreateGroupDialog />,
      }),
    );
  };

  const Header = useCallback(
    () =>
      (
        <GroupChannelListHeader
          renderMiddle={() =>
            <GroupChannelListTitle />}
          renderIconButton={() =>
            (
              <Box onClick={showm} sx={styles.create}>
                <Icon type={IconTypes.CREATE} fillColor="PRIMARY" />
              </Box>
            )}
        />
      ),
    [],
  );

  useEffect(() => {
    dispatch(getGroupChannelList());
  }, []);

  const handleChannelSelect = (channel: any) => {
    const { url, _name } = channel ?? {};
    const {
      user_type: userType,
      users,
      personnelId,
    } = channelMetadata[url] ?? {};
    // BLOCK CLICK EVENTS IF ASSIGNED TO OTHER USER
    if (users && users !== userId) return;

    setFirstMessage(false);
    setChannelUrl({
      userType,
      personnelId,
      url: url ?? '',
      channelName: _name,
    });
  };

  useEffect(() => {
    if (firstMessage) {
      assignToCurrentUser(userId, channelUrl.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstMessage]);

  return (
    <Box sx={styles.container}>
      <GroupChannelList
        selectedChannelUrl={channelUrl.url}
        disableAutoSelect
        renderHeader={Header}
        channelListQueryParams={{ includeEmpty: true }}
        renderChannelPreview={(props) =>
          (
            <GroupChannelPreview
              channelData={channelMetadata}
              props={props}
              setChannelUrl={setChannelMetadata}
            />
          )}
        onChannelSelect={handleChannelSelect}
        onChannelCreated={stub}
      />

      {channelUrl && (
        <GroupChannel
          onBeforeSendUserMessage={(params) => {
            setFirstMessage(true);
            return params;
          }}
          onChatHeaderActionClick={goToUserProfile({
            personnelId: channelUrl.personnelId,
            userType:
              HelpUserTypes[channelUrl.userType as keyof typeof HelpUserTypes],
          })}
          channelUrl={channelUrl.url}
          renderChannelHeader={(props) =>
            (
              <GroupChannelHeader
                {...props}
                renderLeft={() =>
                  <div />}
                renderMiddle={() =>
                  (
                    <GroupChannelTitle
                      title={channelUrl.channelName}
                      url={channelUrl.url}
                    />
                  )}
              />
            )}
          renderMessage={CustomMessage}
        />
      )}
    </Box>
  );
};

export default ChatWindow;
