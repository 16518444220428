export default {
  login: 'Login',
  loading: 'Loading',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  email: 'Email',
  home: 'Home',
  homeMessage: 'Welcome',
  profile: 'Profile',
  role: 'Role',
  confirm: 'Confirm',
  loginAsAnotherUser: 'Login as another user',
  profileMessage:
    'And this one has a nav, but with a different color than the login.',
  logout: 'Logout',
  clear: 'Clear',
  apply: 'Apply',
  totalHrs: '{0} Hrs',
  availability: 'Availability',
  availabilities: 'Availabilities',
  dashboardAvailability: 'My Availabilities',
  loginAsUsername: 'Login as {0}',
  requested: 'Requested',
  search: 'Search',
  phoneValidation: '{0} must be valid number',
  addAvailability: 'Add Availability',
  booked: 'Booked',
  legend: 'Legend',
  summary: 'Summary',
  workshiftType: 'Workshifts Type',
  unfulfilled: 'Unfulfilled',
  posted: 'Posted',
  workshift: 'Workshift',
  workshifts: 'Workshifts',
  pageWorkshift: 'Workshifts',
  orContinueWith: 'or continue with',
  orSignUpwith: 'or Sign Up with',
  alreadyMember: 'Already a member? ',
  noAccount: 'No Account ? ',
  signup: 'Sign up',
  forgetPassword: 'Forget password ?',
  uploadYour: 'Upload Your {0}',
  photo: 'Photo',
  recurrence: 'Recurrence',
  resume: 'Resume',
  registrationInfo: 'Registration Information',
  submit: 'Submit',
  general: 'General',
  professionalInfo: 'Professional Info',
  software: 'Software',
  softwareSingular: 'Software',
  contact: 'Contact',
  languages: 'Languages',
  language: 'Language',
  urgentCall: 'Pharmaplan',
  help: 'Help',
  pharmaplan: 'PharmaPlan',
  changePassword: 'Change Password',
  pharmacyInfo: 'Pharmacy Info',
  primary: 'Primary',
  secondary: 'Secondary',
  accounting: 'Accounting',
  account: 'Account',
  showPharmacyLocation: 'View Pharmacy Location',
  showDetails: 'Show Booking Details',
  alphaOnly: 'Names should not contain numbers',
  emailValid: 'Email must be a valid email',
  passwordMin: 'Password must be at least {0} characters',
  contacts: 'Contacts',
  settings: 'Settings',
  firstName: 'First Name',
  lastName: 'Last Name',
  preferredLanguage: 'Preferred Language',
  myGeographicAvailability: 'My Geographic Preferences',
  save: 'Save',
  areYouIncorporated: 'Are you incorporated?',
  licenseNo: 'License No',
  pharmacistCapacity: 'Pharmacist Capacity',
  yearLicensed: 'Year Licensed',
  yearsOfExperience: 'Years of Experience',
  englishLevel: 'English Level',
  frenchLevel: 'French Level',
  otherLanguage: 'Other Language',
  address: 'Address',
  city: 'City',
  province: 'Province',
  postalCode: 'Postal Code',
  homePhone: 'Home Phone',
  mobile: 'Mobile',
  emergency: 'Emergency',
  allowance: 'allowance',
  travel: 'Travel',
  meal: 'Meal',
  preferredWorkingDays: 'Preferred Working Days',
  preferredReplacementDuration: 'Preferred Replacement Duration',
  availabilityReminder: 'Availability Reminder',
  agree: 'Agree',
  name: 'Name',
  phone: 'Phone',
  phoneExt: 'Phone Ext.',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  volume: 'Volume',
  technicians: 'Technicians',
  technician: 'Technician',
  pharmacists: 'Pharmacists',
  pharmacistsLower: 'Pharmacists',
  weekdays: 'Weekdays',
  weekday: 'WeekDays',
  weekdaySpace: 'Week Day',
  weekends: 'Weekend',
  administrator: 'Administrator',
  operations: 'Operations',
  loginEmail: 'Login email',
  ownersName: "Owner's name",
  bannerNumber: 'Banner Number',
  preferences: 'Preferences',
  new: 'New',
  beginner: 'Beginner',
  good: 'Good',
  expert: 'Expert',
  noSoftware: 'No Software Data',
  primaryContact: 'Primary Contact',
  secondaryContact: 'Secondary Contact',
  accountingContact: 'Accounting Contact',
  emailConfirmationsSent: 'Email confirmations are sent to this contact only',
  youSureLogout: 'Are you sure you want to log out?',
  yesLogout: 'Yes, Logout',
  signout: 'Sign Out',
  requestBooking: 'Request Booking',
  postedWorkshift: '{0} posted workshift',
  booking: 'Booking',
  bookingPharmacyDetails: 'Booking Pharmacy Details',
  availableWorkshifts: 'Available Workshifts',
  availableWorkshiftsMobile: 'Available Workshifts',
  myAvailabilities: 'My Availabilities',
  bookingReport: 'Booking Report',
  requestedWorkshifts: 'Requested Workshifts',
  workshiftReport: 'Workshifts Report',
  workshiftReportMobile: 'Workshifts Report',
  postedWorkshiftReport: 'Posted Workshifts Report',
  filterBy: 'Filter By',
  banner: 'Banner',
  pharmacyName: 'Pharmacy Name',
  pharmacy: 'Pharmacy',
  pharmacistName: 'Pharmacist Name',
  code: 'Code',
  never: 'Never',
  daily: 'Daily',
  remove: 'Remove',
  everyWeekday: 'Every Week Day',
  everyWeekdaySpecified: 'Every Week Day (Mon-Fri)',
  everyWeek: 'Every Week',
  everyMonth: 'Every Month',
  everyYear: 'Every Year',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  time: 'Time',
  date: 'Date',
  setFrequency: 'Set Frequency',
  setEndDate: 'Set End Date',
  action: 'Action',
  pending: 'Pending',
  notAssigned: 'Not Assigned',
  selectedDate: 'Select Date',
  create: 'Create',
  cancelled: 'Cancelled',
  cancel: 'Cancel',
  status: 'Status',
  addWorkshifts: 'Add Workshift',
  customEndDate: 'Custom End Date',
  updateWorkshift: 'Update Workshift',
  deleteWorkshift: 'Delete Workshift',
  myReplacements: 'My Replacements',
  allDay: 'All Day',
  notes: 'Notes',
  send: 'Send',
  noWorkshifts: 'No Workshifts!',
  day: 'Day',
  update: 'Update',
  updateAvailability: 'Update Availability',
  deleteAvailability: 'Delete Availability',
  resetPassword: 'Reset Password',
  resetPassDesc: 'Your email that you use for login into PharmaPlan',
  createNewPassword: 'Create New Password',
  typeReplacement: 'Type Replacement',
  createNewPasswordDesc:
    'Your new password must be different from previously used passwords.',
  checkYourEmail: 'Check your email',
  checkYourEmailDesc:
    'We have sent a password recover instruction to your email.',
  openEmailApp: 'Open email app',
  minimumHourlyRate: 'Minimum Hourly Rate',
  enterHourlyRate: 'Enter your hourly rate',
  searchAvailability: 'Search Availability',
  searchCurrentAvailability: 'Search Current Availability',
  history: 'History',
  exportAsAPdf: 'Export as a PDF',
  cancelBookedWorkshift: 'Cancel Booked Workshift',
  cancelWorkshift: 'Cancel Requested Workshift',
  requestCancellationBooking: 'Request Cancellation Booking',
  created: 'Created',
  updated: 'Updated',
  myUserNotes: "My {0}'s Notes",
  myUserHistory: "My {0}'s History",
  newNote: 'New Note',
  typeANewNote: 'Type a new note',
  selectDate: 'Select Date',
  createNewNote: 'Create New Note',
  pharmacist: 'Pharmacist',
  workshiftDetails: 'Workshift Details',
  pleaseEnableLocation: 'Please Enable your location',
  close: 'Close',
  myReplacementPharmacies: 'My Replacement Pharmacies',
  repeat: 'Repeat',
  replacements: 'Replacements',
  everyDay: 'Every Day',
  onDay: 'on day',
  onThe: 'on the',
  first: 'first',
  second: 'second',
  third: 'third',
  fourth: 'fourth',
  typeADay: 'Type a day',
  selectMonth: 'Select Month',
  selectWeekNumber: 'Select week number',
  selectWeekDay: 'Select week day',
  weekDaysAndMonths: 'Week Days & Months',
  recurrenceName: 'recurrence',
  repeatEvery: 'Repeat Every',
  pickFromPhotos: 'Pick from Photos',
  takeAPhoto: 'Take a Photo',
  gallery: 'Gallery',
  camera: 'Camera',
  english: 'English',
  french: 'Français',
  startTime: 'Start Time',
  endTime: 'End Time',
  manageWorkshifts: 'Manage Workshifts',
  startDate: 'Start Date',
  endDate: 'End Date',
  saveGeneralProfile: 'save_general_Profile',
  saveProInfoProfile: 'save_proInfo_Profile',
  saveContact: 'save_contact_Profile',
  savedSuccessfully: 'Saved Successfully',
  invalidEmail: 'Invalid Email or Password!',
  thankyouRegistration:
    'Thank you for registration!\nYour application is pending activation.',
  done: 'Done',
  languageValidation:
    "Divide the languages by '-' notation\nExample: Arabic - Chinese",
  provinceValidation: 'Province only 2 characters',
  postalCodeValidation: 'Invalid Format',
  successMessage: 'Your profile data has been saved',
  errorMessage: 'Some Error happened will update you later!',
  wrongFormat: 'Wrong Format',
  ok: 'OK',
  selectTime: 'Select Time',
  error: 'Error!',
  rememberMe: 'Remember me',
  area: 'Area',
  requiredField: '* Required',
  graduationYearValidation: 'Graduation year must be between 1970 - 2020',
  paswordsnotMatch: 'Passwords are not match!',
  passwordsMustMatch: 'Passwords must match',
  minimumSixCharacters: '{0} must be at least 6 characters',
  cancelBooking: 'Cancel Booking',
  cancelRequest: 'Cancel Request',
  editAvailability: 'Edit Availability',
  editEvent: 'Edit {0}',
  deleteNote: 'Delete Note',
  updateNote: 'Update Note',
  weekVolumes: 'Weekdays Volume',
  weekEndVolumes: 'Weekend Volume',
  region: 'Region',
  userDashboard: "{0}'s Dashboard",
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  updatPastAvailabilityError: 'Past Availabilities cannot be edited!',
  updatePastWorkshiftError: 'Past Workshifts cannot be edited!',
  permissionDenied: 'Permission Denied',
  allowCamera: 'You must allow Pharmaplan to use Camera',
  weakLevel: 'Weak',
  goodLevel: 'Good',
  fluentLevel: 'Fluent',
  pleaseSelectDate: 'Please select a date',
  minCharacters:
    'Password must contain at least one uppercase letter, one digit, one special character, and be at least 8 characters long.',
  numberValidation: 'must be a number',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  allRegions: 'All Regions',
  locationPermessionDenied: 'User denied the request for location!',
  positionUnavailable: 'Location information is unavailable!',
  timeout: 'The request to get user location timed out!',
  activityNull: 'An unknown error occurred!',
  noEventFound: 'No event found!',
  pharmacyFax: 'Pharmacy Fax',
  emergencyFax: 'Emergency Fax',
  primaryContactName: 'Primary Contact Name',
  primaryContactPhone: 'Primary Contact Phone',
  contactPhoneExtension: 'Contact Phone Extension',
  primaryContactMobile: 'Primary Contact Mobile',
  primaryContactEmail: 'Primary Contact Email',
  secondaryContactName: 'Secondary Contact Name',
  secondaryContactPhone: 'Secondary Contact Phone',
  secondaryContactMobile: 'Secondary Contact Mobile',
  secondaryContactEmail: 'Secondary Contact Email',
  accountingContactName: 'Accounting Contact Name',
  accountingContactPhone: 'Accounting Contact Phone',
  accountingContactMobile: 'Accounting Contact Mobile',
  accountingContactEmail: 'Accounting Contact Email',
  coordinates: 'Coordinates',
  ownerCompanyName: "Owner's Company Name",
  contactPreferences: 'Contact Preferences',
  emergencyPhone: 'Emergency Phone',
  fax: 'Fax',
  softwareFamiliarity: 'Software Familiarity',
  pharmacistTotalRating: 'Pharmacist Total Rating',
  reviews: 'Reviews',
  noDataFound: 'No Data Found',
  pharmacistDetails: 'Pharmacist Details',
  pleaseSelectAction: 'Please select an action to continue!',
  updateSeries: 'Update Series',
  updateOccurrence: 'Update Occurrence',
  pharmacyPhone: 'Pharmacy Phone number',
  myReplacementUser: 'My Replacement {0}',
  selectedContactPreference: 'Selected Contact Preferences',
  pleaseSelectOne: '* Select one at least',
  number: 'Number',
  onlySpace: 'This field cannot contain only blankspaces',
  unfamiliar: 'Unfamiliar',
  veryGood: 'Very Good',
  next: 'Next',
  checkMail: 'checkMail',
  clickToUpload: 'Click to Upload',
  or: 'or',
  ourCommitment:
    'our commitment is to bring you a solution that is personalized to your needs.',
  dragAndDrop: 'Drag and Drop',
  en: 'EN',
  on: 'On',
  plan: 'Plan',
  fr: 'FR',
  pharmaPlan: 'PharmaPlan',
  pharma: 'Pharma',
  pharmagap: 'PharmaGap',
  availabilitesReports: 'Availabilities Report',
  enterEmail: 'Enter Email',
  enterCred: 'Enter {0}',
  continueWith: 'or continue with',
  at: 'At',
  signIn: 'Sign In',
  welcomeTo: 'Welcome to',
  findOutMore: 'Find out more',
  from: 'From',
  forgotPass: 'Forgot Password?',
  professionalReplacement: 'Need a professional\n replacement?',
  to: 'To',
  monthlyOnDay: 'Monthly on Day {0}',
  monthlyOnFourth: 'Monthly on Fourth {0}',
  occursEvery: 'Occurs every',
  dayFeedbackMessage: 'day',
  daysFeedbackMessage: '{0} days',
  weekFeedbackMessage: 'week on {0}',
  weeksFeedbackMessage: '{0} weeks on {1}',
  monthFeedbackMessage: 'month {0}',
  monthsFeedbackMessage: '{0} months {1}',
  yearFeedbackMessage: 'year {0}',
  yearsFeedbackMessage: '{0} years {1}',
  imageUploadNote: 'PNG, JPEG or SVG format (max. size 800x400 px)',
  pdfUploadNote: 'PDF or doxe format (max. size 2 MB)',
  bookingReports: 'Booking Reports',
  bookingDetails: 'Booking Details',
  viewPharmacyLocation: 'View Pharmacy Location',
  viewPharmacyLocationMobile: 'View Pharmacy Location',
  reportTitle: '{0} Reports',
  available: 'Available',
  requestedCount: '{0} Requested',
  bookedCount: '{0} Booked',
  postedCount: '{0} Posted',
  customRecurrence: 'Custom Recurrence',
  replacementWorkshifTitle: 'Replacement WorkShift on {0}',
  ends: 'Ends',
  moreInfo: 'More Info',
  recurrences: 'Recurrences',
  delete: 'Delete',
  allday: 'All day',
  hoursWorked: '{0} Hrs',
  welcomeUser: 'Welcome, {0}!',
  fileUploadSuccess: 'File Uploaded Successfully',
  manageYourInfo: 'Manage your info',
  hours: 'Hours',
  dashboard: 'Dashboard',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  custom: 'Custom',
  doNotRepeat: 'Do not repeat',
  myReplacment: ' My Replacement',
  reports: 'Reports',
  createdEvent: 'Created {0}',
  createEvent: 'Create {0}',
  favourite: 'Favorite',
  rowsPerPage: 'rows per page',
  francais: 'Francais',
  uploadYourResume: 'Upload Your Resume',
  profissonalWork: 'Profissonal work',
  profissonalInfo: 'Profissonal Info',
  addressPhoneNo: 'ADDRESS & PHONE NO.',
  start: 'Start',
  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thurs: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  hourlyRate: 'Hourly Rate',
  hourlyRates: 'Hourly Rates',
  copyRight: 'Copyright© 2010-{0}',
  zoomJar: 'ZoomJar Technology Inc.',
  rightsReserved: 'All rights reserved.',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  siteMap: 'Site Map',
  resetDesc: 'Your email that you use for login into PharmaPlan',
  passwordRecoveryMail:
    'We have sent a password recovery instruction to your email.',
  createNewPw: 'Create New Password',
  newPasswordDesc:
    'Your new password must to be different from previous used passwords.',
  backToLogin: 'Back to login',
  openYourEmail: 'Open your email',
  urgentNeed: 'Urgent Need?',
  reset: 'Reset',
  profileSaved: 'Your Profile data has been saved',
  myPharmacyHistory: "My Pharmacy's History",
  endsOn: 'Ends On',
  currentTimeValidation: 'Please enter time above current time',
  enterAhead: 'Please enter time minimum {0} hours ahead of from',
  pleaseEnterValidTime: 'Please enter a valid time',
  report: '{0} Report',
  pageHeaderTitle: 'PharmaPlan - {0}',
  myPharmacies: 'My Pharmacies',
  yes: 'Yes',
  no: 'No',
  accepted: 'Accepted',
  pleaseEnterValidDate: 'Please enter a valid date',
  pharmaciesServed: 'Pharmacies Served',
  totalReplacements: 'Total Replacements',
  totalHours: 'Total Hours',
  replacementType: 'Replacement Type',
  picture: 'picture',
  emailFr: 'Email',
  signupAs: 'Sign Up as a {0}',
  back: 'Back',
  today: 'Today',
  releaseDate: 'Release date',
  version: 'Version',
  loginAs: 'Login As',
  yesCancel: 'Yes, Cancel',
  confirmation: 'Are you sure you want to cancel the booking?',
  cancelBookingConfirmation: 'Cancel Booking Request',
  records: 'Records',
  singlePostedWorkshift: 'Posted Workshift',
  selectRole: 'Select your role',
  join: 'Join',
  pharmacyOwner: 'Pharmacy (Pharmacy Owner)',
  minimumDate: 'Past dates not allowed',
  signupSuccessful: 'You have been signed up!',
  enterOtp: 'Enter One-Time Password',
  otpSent: 'OTP has been sent to your registered email',
  verify: 'Verify',
  resendOtpIn: 'Resend the code in',
  resendOtp: 'Resend the code',
  faq: 'FAQs',
  backGuide: 'Back',
  end: 'End',
  skip: 'Skip',

  profileBtn:
    'The “Profile” button allows you to manage your personal and professional information, change your password, update your account settings, and check the FAQs.',
  dashboardBtn:
    'The “Dashboard” is your main interface that displays your work shifts information in a Month View.',
  availabilityBtn:
    'The “Availability” button allows you to manage your availabilities.  Tap the “+” button to create single or recurrent availabilities.',
  myReplacementsBtn:
    '"My Replacement Pharmacists" button allows you to view your historical replacement pharmacists.',
  reportsBtn:
    'The “Reports” button gives you access to data or insights about your activities and performance.',
  datePicker:
    'The “Date Picker” allows you to select a specific month to be displayed on the dashboard.',
  monthView:
    'The “Month View” shows you work shifts information of a specific month.',
  weekView:
    'The “Week View” shows you work shifts information of a specific week.',
  dayView:
    'The “Day View” shows you work shifts information of a specific day.',
  searchBtn:
    'The “Search” button allows you to filter work shifts in the current View.',
  legendGuide:
    'The “Legend” shows the total hours per shift type (color coded). You can choose which shift types you want to see in the dashboard calendar by checking or unchecking the circles next to them.',
  mapView:
    'The “Map View” allows you to visualize the geographical location of the available, requested and booked work shifts.  Additionally, it provides the option to display pharmacies nearby your current location based on your preferred distance radius configured in your profile. You can switch back to the “Calendar View” by selecting the “Calendar View” icon.',
  todayBtn:
    'The “Today” button takes you back quickly to the current Month, Week or Day View.',

  profileBtnPharmacy:
    'The “Profile” button allows you to manage your pharmacy information, change your password, update your account settings, and check the FAQs.',
  myWorkShifts:
    'The “My Work Shifts Management” button allows you to manage your work shifts.  Tap the “+” button to create single or recurrent work shifts.',
  reportsBtnPharmacy:
    'The “Reports” button gives you access to data about your activities.',
  filterBtn:
    'The “Filter” button allows you to filter work shifts by type in the current View.',

  tempAvailability: 'New availability',
  confirmAvailabilityMessage: 'Please confirm you have created {0} {1}',
  confirmEvent: 'Confirm {0}',
  rate: 'Rate',

  preferredTime: 'Preferred Time System',
  twentyFourHours: '24 hrs format',
  twelveHours: '12 hrs (AM/PM)',
  skipConfirmation: "Don't show this message again",

  tooltipShowDetails: 'Show details',
  termsAndConditions: 'Terms & Conditions',
  termsAndConditionsHeader: 'PharmaPlan Terms of Service',
  agreeOn: 'Agree on ',
  termsAndConditionsValidation: '* You need to accept the terms and conditions',

  // TRANSLATE
  multiple: 'Multiple',
  userLocation: '{0} location',
  pharmacyLocation: 'Pharmacy Location',
  pharmacistLocation: 'Pharmacist Location',
  myLocationOnMap: 'My Location On Map',
  mapButtonLabel: 'Pick on the Map',
  mapSelectLabel: 'Select...',
  pickYourLocationOnMap: 'Pick your Location on the map',
  accept: 'Accept',
  reject: 'Reject',
  bookingRequests: 'Booking Requests',
  bookingRequestsNum: `Booking Requests ({0})`,
  numAway: '{0} km away',
  requestedOn: 'Requested on: {0}',
  distance: 'Distance',
  requestedIn: 'Requested in: {0}',
  workshiftDate: 'Workshift: {0} ',
  counterOffer: 'Counter Offer',

  billDetails: 'Bill Details',
  finalBillDetails: 'Final Bill Details',
  softwareExpertise: 'Software Expertise',
  viewMore: 'View more',
  viewLess: 'View less',
  total: 'Total',
  contactDetails: 'Contact Details',
  sortBy: 'Sort by',
  rating: 'Rating',
  requestedDate: 'Requested Date',
  totalCost: 'Total Cost',
  reportAnIssue: 'Report an Issue',
  ratePharmacist: 'Rate Pharmacist',
  pharmacistRating: 'Pharmacist Rating',
  bookingCost: 'Booking Cost',
  accomodation: 'Accommodation',
  pharmaPlanFees: 'PharmaGap Fees',
  pharmacyOperation: 'Pharmacy Operation',
  submitCounterOffer: 'Submit Counter Offer?',
  viewPostedWorkshifts: 'View Posted Workshifts ({0})',
  viewPostedWorkshiftsMobile: 'View Posted Workshifts ({0})',
  workshiftPosted: 'Workshift Posted',
  covered: 'Covered',
  notCovered: 'Not Covered',
  fixedAmount: 'Fixed Amount',
  calculate: 'Calculate',
  rateAndAllowances: 'Rate & Allowances',
  travelAllowances: 'Travel Allowances',
  accommodationAllowances: 'Accommodation Allowances',
  mealAllowances: 'Meal Allowances',
  emergencyServiceFees: 'Emergency Service Fees',
  optionalEmergencyFees: 'Emergency Service Fees (Optional)',
  forEveryKmAbove: 'for every 1km above ',
  noRequests: 'No Requests',
  numberRequests: '{0} Requests',
  numberRequest: '{0} Request',
  otherRequests: '+{0} other requests',
  notRecurring: 'Not Recurring',
  town: 'Town',
  seeMore: 'See More',
  workshiftRequested: 'Workshift Requested',
  discardChanges: 'Discard Changes',
  dayShift: 'dayshift',
  nightShift: 'nightshift',
  pharmaplanCharges: '*PharmaPlan charges {0}% on the booking cost',
  applicableTaxes: '*The above amount is exclusive of any applicable taxes',
  requestedAgo: 'Requested {0} {1} ago',
  smallHours: 'hours',
  smallDays: 'days',
  enterFixedAmount: 'Enter Fixed Amount',
  calculated: 'Calculated',
  comment: 'Comment',
  confirmationPending: 'Confirmation pending',
  viewRating: 'View Rating',
  createWorkshift: 'Create Workshift',
  minutes: 'minutes',
  goodNews: 'Good News! You have been selected!',
  requestWillBeAutoRejected:
    'Request will be auto-rejected after pending time is elapsed',
  confirmIn: 'Confirm in {0} Hours {1} minutes',
  confirmBefore: 'Confirm before {0}',
  congratulations: 'Congratulations!',
  confirmedBookings: 'Booking is Confirmed.',
  pendingBooking: 'Booking Pending',
  bookingPendingForConfirmation:
    'Booking is Pending \n for Pharmacist Confirmation.',
  workshiftBooked: 'Workshift Booked',
  offer: 'offer',
  enterAmount: 'Enter Amount',
  rejectionConfirm: 'Are you sure you want to reject this booking?',
  rejectBooking: 'Reject Booking',
  myPharmacistRating: "My Pharmacist's Rating",
  pleaseEnterLessThan: 'Please enter less than {0}',
  requestsNum: 'Requests ({0})',
  before: 'Before',
  updateTiming: 'Update Timing',
  saveChanges: 'Save Changes',
  availableWorkshift: 'Available Workshift',
  requestedWorkshift: 'Requested Workshift',
  bookedWorkshift: 'Booked Workshift',
  counterOfferSubmitted: 'Your Counter Offer is submitted',
  workshiftHasAConfirmed:
    'Workshift has a confirmed Booking. Are you sure you want to update?',
  proceed: 'Proceed',
  updatesArePending: 'Updates are pending for Booked Pharmacist Confirmation.',
  updateWillBeAutoCancelled:
    'Update will be auto-canceled after pending time is elapsed ',
  updateWillBeAutoRejected:
    'Updates will be auto-rejected after pending time is elapsed ',
  approveUpdates: 'Approve Updates',
  declineUpdates: 'Decline Updates',
  pharmacyUpdatedWorking: 'Pharmacy Updated working hours',
  describeIssue: 'Describe the issue',
  issueRefNo: 'Issue Reference No.:',
  issueResp: 'Thank you! We will respond\n to you shortly',
  submitSuccessfully: 'Submitted Successfully',
  submitIssue: 'Submit Issue',
  selectIssue: 'Select issue',
  issueDescription: 'Issue Description',
  youHaveEnteredCancelation:
    'You have entered the No Cancelation Period! Please contact PharmaPlan',
  cancelBookingMightIncur: 'Canceling booking might incur fees as per the',
  tAndC: 'T&C',
  rejectCounterOffer: 'Reject Counter Offer?',
  acceptCounterOffer: 'Accept Counter Offer?',
  areYouSureRejectCounter:
    'Are you sure you want to reject \n this counter offer?',
  areYouSureAcceptCounter:
    'Are you sure you want to accept \n this counter offer?',
  shiftDetails: 'Workshift Details',
  times: 'Time',
  mustBeNumber: 'must be a number',
  submitCounterOfferDef: 'Submit Counter Offer',
  confirmDeleteWorkshift: 'Are you sure you want to delete this workshift?',
  volumeValidation: '(e.g. 1234 or 12-34)',
  backUpdatePrompt: 'Please update the operations information',
  updateOperationMessage:
    'To proceed with the Pharmaplan application, please update the operations information.',
  pleaseEnterMoreThan: 'Please enter more than {0}',
  youCannotUpdatePastBookingTiming: 'You cannot update past booking timing',
  updateOperation: 'Update Operations',
  requestSuccess: 'The workshift request has been submitted successfully.',
  continueCreation:
    'Would you like to create more {0}? If no, you will be directed to the Dashboard',
  createMore: 'Create More',
  softwareWarning:
    'Your {0} knowledge is insufficient ({1}%). If this has changed, please update your profile.',
  ratingSuccessful: 'Pharmacist rating has been submitted successfully!',
  expiredError: 'The workshift you are trying to access has expired.',
  noDataAvailable: 'No data available!',

  bookingCanceled: 'Your booking has been canceled.',
  postalCodeHint: 'eg: A1A1A1',
  registerBiometric:
    'Enable your biometric data for a more secure login experience?',
  biometric: 'Registration for Biometric',
  enableBiometric: 'Enable Biometric',
  validateBiometric: 'Validate biometrics',
  registrationSuccessfull:
    'Your biometric registration process has been successfully completed.',
  myReplacementPharmacists: 'My Replacement Pharmacists',
  replayWalkthrough: 'Replay Walkthrough Help',
  pleaseEnableBiometric: 'Please enable the biometric on your device',
  preferredDistanceRadius: 'Preferred Distance Radius (km)',
  contractDetails: 'Contract Details',
  pharmacistContractValue: 'Pharmacist Contract Value',
  contractValue: 'Contract Value',
  markAsRead: 'Mark all as read',
  deleteMyAccount: 'Delete my account',
  confirmBy: 'Confirm by {0}',
  notifications: 'Notifications',
  pharmaciesNearby: 'Pharmacies nearby',
  pleaseEnableAccessLocation: 'Please enable access to your location',
  deleteAccountConfirm: 'Are you sure, you want to delete the account?',
  aboutUs: 'About Us',
  themeSettings: 'Theme Settings',
  light: 'Light',
  dark: 'Dark',
  auto: 'Auto',
  themeChanged: 'Your theme settings have been saved.',
  read: 'Read',

  deleteConfirmationPharmacy:
    'All your upcoming workshift postings/ requests would get deleted, are you sure you want to proceed with deletion?',
  deleteConfirmationPharmacist:
    'All your upcoming availabilities/ requests would get deleted, are you sure you want to proceed with deletion?',
  usesCookie: 'Our website uses cookies',
  cookieConsent:
    'We use only the essential cookies to optimize your platform experience. Rest assured, no data is shared with any third-party platforms for sales or marketing purposes. Your privacy is our priority. Learn more in our',
  cookiePolicy: 'Cookie Policy',
  updateNow: 'Update Now',
  updateRequired: 'Update Required!',
  acceptCookies: 'Accept Cookies',
  incomeReport: 'Income Report',
  totalRevenue: 'Total Revenue',
  totalWorkshifts: 'Total Workshifts',
  bookedWorkshiftDetails: 'Booked Workshift Details',
  pleaseTryAgain: 'Please try again',
  timeColon: 'Time: {0}',
  availableWorkshiftFormatted: 'Available Workshifts: {0}',
  pharmacies: 'Pharmacies',
  sendCommunicationsCopyTo: 'Send communications copy to',
  tax: 'Tx',
  workshiftWithDate: 'Workshift Date: {0}',
  backTo: 'Back to',
  viewDetails: 'View Details',
  fullScreen: 'Full Screen',
  workshiftDateText: 'Workshift Date',
  hourlyRateSuffix: '${0}/Hr',
  //TRANSLATE
  configuration: 'Configuration',
  admin: 'Admin',
  logs: 'Logs',
  expressBooking: 'Express Booking',
  pendingActivation: 'Pending Activations',
  pendingCancellation: 'Pending Cancellations',
  administratorList: 'Administrator List',
  pharmacistsList: 'Pharmacists List',
  pharmacyList: 'Pharmacy List',
  activityLogs: 'Activity Logs',
  pharmacistsCapacities: 'Pharmacists Capacities',
  softwareList: 'Software list',
  pharmacyChains: 'Pharmacy Chains',
  adminPharmaPlan: 'Admin PharmaPlan',
  pharmacyAdministrators: 'Pharmacy Administrators',
  pharmacyAdministrator: 'Pharmacy Administrator',
  incompatibleMatch: 'Incompatible Match',
  broadcastNotifications: 'Broadcast Notifications',
  postedWorkshiftsReport: 'Posted Workshifts Report',
  requestedReport: 'Requested Report',
  availabilityReport: 'Availability Report',
  activeUsers: 'Active Users',
  adminDashboard: 'Admin Dashboard',
  editWorkshift: 'Edit Workshift',
  pharmacyChain: 'Pharmacy Chain',
  settingsInfo: 'Settings Info',
  noOfDaysToRatePharmacist: 'Number of Days to Rate Pharmacist',
  representativePhone: 'Representative Phone',
  salesForceSecurityToken: 'Salesforce Security Token',
  showAllBookingDetails: 'Show all Booking Details',
  minHoursWorkshift: 'Min Hours Workshift',
  representativeExtPhone: 'Representative Extension Phone',
  urlOfSalesForce: 'URL of Salesforce',
  representativeName: 'Representative Name',
  salesForceUsername: 'Salesforce Username',
  sendAllEmailsBCC: 'Send all emails in BCC to Admin',
  representativeEmail: 'Representative Email',
  salesForcePWD: 'Salesforce Password',
  integrationSalesForce: 'Integration Salesforce',
  matchingAvailabilities: 'Matching Availabilities',
  edit: 'Edit',
  contactName: 'Contact Name',
  generalDetails: 'General Details',
  createdFormat: 'Created {0}',
  location: 'Location',
  approveCancellation: 'Approve Cancellation',
  sendEmailNotification: 'Send Email notification to {0}',
  contactNumber: 'Contact number',
  approve: 'Approve',
  ignore: 'Ignore',
  bookingDate: 'Booking Date',
  ignoreWorkshift: 'Ignore Workshift!',
  sendEmailNotificationPharmacist: 'Send email notification to pharmacist',
  approveBooking: 'Approve Booking',
  notesForSalesOrder: 'Notes For Sales Order',
  pharmacistHourlyRate: 'Pharmacist Hourly Rate',
  pharmacyHourlyRate: 'Pharmacy Hourly Rate',
  book: 'Book',
  matchingAvailabilitiesOfWorkshift: 'Matching Availabilities of Workshift',
  requestCancellation: 'Request Cancellation',
  reason: 'Reason',
  removeFavorite: 'Remove Favorite',
  addFavorite: 'Add Favorite',
  showOtherAvailabilities: 'Show Other Availabilities',
  hideOtherAvailabilities: 'Hide Other Availabilities',
  otherAvailabilities: 'Other Availabilities',
  noMatchingAvailabilitiesToShow: 'No matching availabilities to show.',
  noOtherAvailabilitiesToShow: 'No other availabilities to show.',
  selectedDat: 'Selected Date',
  requestedWorkshiftsFormatted: 'Requested Workshifts: {0}',
  bookedWorkshiftsFormatted: 'Booked Workshifts: {0}',
  useLabSchedulingExt: 'Use lab Scheduling Extensions?',
  pharmaplanID: 'Pharmaplan ID',
  shortPharmacyName: 'Short Pharmacy Name',
  other: 'Other',
  operationalInfo: 'Operational Info',
  graduationYear: 'Graduation Year',
  professionalInformation: 'Professional Information',
  licenseNumber: 'License Number',
  pharmacistProfileDetails: 'Pharmacist Profile Details',
  pharmacyProfileDetails: 'Pharmacy Profile Details',
  selectUser: 'Select {0}',
  saveBooking: 'Save Booking',
  incompatibleWarning:
    'This pharmacist is in incompatible list. Are you sure you want to continue?',
  incompatiblePharmacist: 'Incompatible Pharmacist',
  owners: 'Owners',
  pendingActivationList: 'Pending Activation List',
  representFranchisenumber: 'Represents the franchise number of the pharmacy',
  pharmacistDuringWeek: 'Pharmacists during the week',
  pharmacistOnTheWeekend: 'Pharmacists on the weekend',
  dontSendReminder: "Don't send me reminder",
  technicianHourlyRate: 'Technician Hourly Rate',
  editProfile: 'Edit {0} Profile Details',
  user: 'User',
  enterNameEmail: 'Enter Name/Email',
  takeOwnership: 'Take Ownership',
  activate: 'Activate',
  activateUser: 'Activate User',
  areYouSureActivateUser: 'Are you sure you want to activate this user?',
  areYouSureDeleteEntry: 'Are you sure you want to delete this entry?',
  takeOwnershipProfile: 'Do you want to take ownership of this profile?',
  chooseFile: 'Choose File',
  sizeOfImage: 'The size of the image in MB should be less than 2 MB.',
  addNotes: 'Add Notes',
  uploadYourPicture: 'Upload Your Picture',
  deletedRequests: 'Deleted Requests',
  contactEmail: 'Contact Email',
  deleteEvent: 'Deleted {0}',
  userType: 'User Type',
  all: 'All',
  details: 'Details',
  sizeOfResume:
    'Your resume file should not be larger than 2 MB. The resume file should have an extension of type "doc", "docx" or "pdf".',
  profileDataSaved: 'The profile data has been saved',
  createNew: 'Create New',
  description: 'Description',
  descriptions: 'Descriptions',
  published: 'Published',
  publish: 'Publish',
  addNew: 'Add New',
  pharmacistCapacities: 'Pharmacist Capacities',
  inUse: 'In Use',
  active: 'Active',
  sendCopiesOfSf: 'Send copies of SF messages to',
  adminConfiguration: 'Admin Configuration',
  defaultHourlyRate: 'Default {0} Hourly Rate',
  customSettings: '{0} Settings',
  more: 'More',
  salesforce: 'Salesforce',
  customerServiceRepresentative: 'Customer Service Representative',
  noOptions: 'No options',
  ownedByAnotherAdmin: 'Owned by other admin',
  userActivatedSuccessfully: 'User activated successfully',
  actionCompletedSuccessfully: 'Action completed successfully',
  recordDeletedSuccessfully: 'Record deleted successfully',
  success: 'Success!',
  pharmacistGeographicAvailability: 'Pharmacist Geographic Availability',
  outOfDate: 'Out Of Date',
  detailsSaved: 'Details saved successfully',
  editAdminConfig: 'Edit Admin Configuration',
  sfAdminCreatedSuccessfully: 'SF admin created successfully',
  sfAdminDeletedSuccessfully: 'SF admin deleted successfully',
  sizeValidation:
    'Maximum upload size 100 kb and dimensions is 250 x 250 pixels.',
  uploadFile: 'Upload File',
  cancelledBookings: 'Cancelled Bookings',
  rejectedCancellations: 'Rejected Cancellations',
  pendingBookingCancellations: 'Pending Booking Cancellations',
  requestCode: 'Request Code',
  cancelationRequested: 'Cancelation Requested {0}',
  requestedEvent: 'Requested {0}',
  requestedBy: 'Requested By',
  cancelation: 'Cancelation',
  requestCancellationInfo: 'Request Cancellation Info',
  bookingInfo: 'Booking Info',
  requestStatus: 'Request Status',
  requestType: 'Request Type',
  requestReason: 'Request Reason',
  workshiftCode: 'Workshift Code',
  availabilityCode: 'Availability Code',
  rejectedDate: 'Rejected Date',
  rejectedBy: 'Rejected By',
  rejectionNote: 'Rejection Note',
  cancellationCode: 'Cancellation Code',
  cancellationDate: 'Cancellation Date',
  approvedBy: 'Approved By',
  cancellationNote: 'Cancellation Note',
  rejectedCancellationInfo: 'Rejected Cancellation Info',
  cancellationInfo: 'Cancellation Info',
  rejectCancellation: 'Reject Cancellation',
  approvedSuccess: 'Approved Successfully',
  rejectedSuccess: 'Rejected Successfully',
  activePharmacists: 'Active Pharmacists',
  deactivatedPharmacists: 'Deactivated Pharmacists',
  hiddenPharmacists: 'Hidden Pharmacists',
  deletedPharmacists: 'Deleted Pharmacists',
  enterNotesHere: 'Enter notes here',
  pharmacistBookingList: 'Pharmacist Booking List',
  pharmacyBookingDetails: 'Pharmacy Booking Details',
  pharmacistAvailabilities: 'Pharmacist Availabilities',
  findMatchingWorkshifts: 'Find Matching Workshifts',
  areYouSureHidePharmacist: 'Are you sure you want to hide this pharmacist?',
  hidePharmacist: 'Hide Pharmacist',
  hide: 'Hide',
  deactivate: 'Deactivate',
  areYouSureDeactivatePharmacist:
    'Are you sure you want to deactivate this pharmacist?',
  deactivatePharmacist: 'Deactivate Pharmacist',
  deactivatedSuccessfully: 'Deactivated Successfully',
  hiddenSuccessfuly: 'Hidden Successfully',
  pleaseEnterValidValue: 'Please enter a valid value',
  fromMustBeLessThanTo: "'{0}' value must be less than '{1}' value",
  toMustBeMoreThan: '{0} must be more than {1}',
  areYouSurePublish: 'Are you sure if you want to {0} the record?',
  unpublish: 'Unpublish',
  recordFormat: '{0} Record',
  uploadResume: 'Upload Resume',
  uploadPicture: 'Upload Picture',
  noResultsToDisplay: 'No results to display.',
  owner: 'Owner',
  ownedBy: 'Owned By',
  createMultipleAvailabilities: 'Create Multiple Availabilities',
  availabilitiesCreatedSuccessfully: 'Availabilities Created Successfully',
  matchingWorkshiftsOfAvailability: 'Matching Workshifts of Availability',
  noAvailabilitiesToShow: 'No availabilities to show.',
  noMatchingWorkshiftsToShow: 'No matching workshifts to show',
  dismiss: 'Dismiss',
  incompatible: 'Incompatible',
  compatible: 'Compatible',
  markFormat: 'Mark {0}',
  compatibility: 'Compatibility',
  areYouSureMarkFormat: 'Are you sure you want to mark the pharmacist as {0}?',
  pharmacySoftwareWarning:
    "This Pharmacist's {0} knowledge is insufficient ({1}%). Are you sure you want to proceed? ",
  addedSuccessfullyFormat: 'Data added successfully',
  updatedSuccessfullyFormat: 'Data updated successfully',
  ensureTimeIsAhead:
    'Please ensure that the end time is at least {0} hours ahead.',
  listOfPharmacies: 'List of Pharmacies',
  notesForPharmacy: 'Notes for Pharmacy',
  enterAReason: 'Please enter a reason',
  deactivationDate: 'Deactivation Date',
  unhide: 'Unhide',
  areYouSureActivatePharmacist:
    'Are you sure you want to activate this pharmacist?',
  activateFormat: 'Activate {0}?',
  unhideFormat: 'Unhide {0}',
  areYouSureUnhidePharmacist:
    'Are you sure you want to unhide this pharmacist?',
  deletion: 'Deletion',
  areYouSureRequestWorkshift:
    'Are you sure you want to request this workshift?',
  pharmacistList: 'Pharmacist List',
  listOfPharmacists: 'List of Pharmacists',
  listOfWorkshifts: 'List of Workshifts',
  noAvailable: 'No {0} Available!',
  listOfBookedPharmacists: 'List of Booked Pharmacies',
  incompatiblePharmacistDescription:
    "Pharmacists that are mapped incompatible to this pharmacy will not be able to view this pharmacy's posted workshifts.",
  allPharmacists: 'All Pharmacists',
  add: 'Add',
  selectAPharmacy: 'Select a Pharmacy',
  updateList: 'Update List',
  incompatibleMatchPharmacist: 'Incompatible Match - Pharmacist',
  updateIncompatibleMatchPharmacist: 'Update Incompatible Match - Pharmacist',
  emptyIncompatiblePrompt:
    'No available data to show. Please search from above filters.',
  saveIncompatibleMatch: 'Save Incompatible Match',
  areYouSureSaveIncompatible:
    'Are you sure you want to save the updated incompatible pharmacist list?',
  youCannotMoveMoreIncompatiblePharmacists:
    'You cannot move more than one pharmacist to incompatible pharmacists.',
  youMoveUserIncompatiblePharmacist:
    'You moved “{0}” to Incompatible Pharmacists list. Save to confirm changes.',
  deleteRecords: 'Delete Records',
  selectAll: 'Select All',
  deleteSelected: 'Delete Selected',
  thisRecordDeletePermanently:
    'Are you sure you want to permanently delete the {0} selected records?',
  areYouSureActivatePharmacy:
    'Are you sure you want to activate this pharmacy?',
  areYouSureDeactivatePharmacy:
    'Are you sure you want to deactivate this pharmacy?',
  deactivateFormat: 'Deactivate {0}',
  workshiftCreatedSuccessfully: 'Workshift created successfully',
  availabilityCreatedSuccessfully: 'Availability created successfully',
  workshiftDeletedSuccessfully: 'Workshift deleted successfully',
  noIncompatibleMatches:
    'Sorry, this pharmacy does not have any incompatible matches.',
  replacementHourlyRate: 'Replacement Hourly Rate',
  wsCode: 'WS Code',
  replacementName: 'Replacement Name',
  free: 'Free',
  deletedDate: 'Deleted Date',
  deactivatedDate: 'Deactivated Date',
  findMatchingAvailabilities: 'Find Matching Availabilities',
  pleaseEnterStartAndEndDate:
    'Please enter the start and end date to view the report',
  dateTimeSuffix: '{0} Date | Time',
  lastActivity: 'Last Activity',
  lastLogin: 'Last Login',
  superadmin: 'Superadmin',
  inactive: 'Inactive',
  areYouSureDeactivateFormat: 'Are you sure you want to deactivate this {0}',
  areYouSureActivateFormat: 'Are you sure you want to activate this {0}',
  editProfileOnly: 'Edit Profile',
  bookings: 'Bookings',
  adminNotes: 'Admin Notes',
  view: 'View {0}',
  historyOfBookings: 'History of Bookings',
  notesForPharmacist: 'Notes for Pharmacist',
  contactPreference: 'Contact Preference',
  confirmDeleteRecord:
    'Are you sure you want to delete this configuration record?',
  deletedSuccessfully: 'Deleted Successfully!',
  deleteConfiguration: 'Delete Configuration',
  type: 'Type',
  replacement: 'Replacement',
  createMultipleFullDay: 'Create Multiple Full Day Availabilities',
  adminContinueCreation:
    'Would you like to create more {0}? If no, you will be directed to the Pharmacist list',
  posting: 'Posting',
  changeParameters: 'Change Parameters',
  activation: 'Activation',
  setupIncompatibleMatch: 'Setup Incompatible Match',
  logsView: 'Logs View',
  broadcastNotification: 'Broadcast Notification',
  addNewFormat: 'Add New {0}',
  selectPermissions: 'Select Permissions',
  areYouSureEditEmail: 'Are you sure you want to edit this email?',
  setPassword: 'Set Password',
  confirmWorkshiftCancel: 'Are you sure you want to cancel the workshift?',
  bookingCreatedSuccessfully: 'Booking created successfully!',
  bookingEditedSuccessfully: 'Booking edited successfully!',
  activePharmacies: 'Active Pharmacies',
  deactivatedPharmacies: 'Deactivated Pharmacies',
  deletedPharmacies: 'Deleted Pharmacies',
  bannerLogo: 'Banner Logo',
  banners: 'Banners',
  globalSettings: 'Global Settings',
  broadcast: 'Broadcast',
  incompatibility: 'Incompatibility',
  pharmacistRateCannotBeMoreThanPharmacy:
    'Pharmacist Rate cannot be more than the Pharmacy Rate.',
  confirmDeleteAvailability:
    'Are you sure you want to delete this availability?',
  level: 'Level',
  detailsSearch: 'Details Search',
  capacityFrom: 'Capacity From',
  adminCreatedSuccessfully: 'Admin created successfully',
  accountDetailsHaveBeenEmailed:
    'Account details have been emailed to the new user',
  areYouSureCreateAvailabilities:
    'Are you sure you want to create these availabilities',
  pharmaciesList: 'Pharmacies List',
  administrators: 'Administrators',
  administratorsList: 'Administrators List',
  activeAdmins: 'Active Admins',
  inactiveAdmins: 'Inactive Admins',
  userDeletedAccounts: 'User-Deleted Accounts',
  deletedActivationRequests: 'Deleted Activation Requests',
  upcomingWorkshifts: 'Upcoming Workshifts',
  pastWorkshifts: 'Past Workshifts',
  permanentDelete: 'Permanent Delete',
  pharmacyFeedback: 'Pharmacy Feedback',
  allRatings: 'All Ratings',
  createdOn: 'Created on',
  averageRating: 'Average Rating',
  editNotAllowed: 'Edit not allowed',
  thisFeatureIsUnderDev:
    'This feature is currently under development and will be available soon.',
  connectedUsers: 'Connected Users',
  tech: 'Tech',
  changeEmailConfirmation:
    "This requires transitioning to a PharmaPlan email user from {0} and creating a password. Don't worry, we will walk you through the steps!",
  continue: 'Continue',
  weSentAnEmail: 'We sent an email to {0} with a link to create your password.',
  pleaseEnterCurrentPassword:
    'Please enter your current password and new email to continue.',
  currentPassword: 'Current Password',
  newEmail: 'New Email',
  setNewEmail: 'Set New Email',
  sessionReset: 'Session Reset',
  logoutConfirmation:
    'Success! Your email has been successfully updated. Please note that you will be automatically logged out of all active sessions and will need to sign in again later',
  otpSentNew: 'OTP has been sent to your new email',

  by: 'By',
  groupName: 'Group Name',
  recepients: 'Recepients',
  notificationType: 'Notification Type',
  sent: 'Sent',
  group: 'Group',
  broadcastGroups: 'Broadcast Groups',
  createNewGroup: 'Create New Group',
  saveGroup: 'Save Group',
  selectedUsers: 'Selected Users',
  groupCreatedSuccessfully: 'Group Created Successfully',
  areYouSureResetSelect:
    'Are you sure you want to reset your selection? Please note that clicking yes will unselect all selected items',
  resetSelection: 'Reset Selection',
  editGroup: 'Edit Group',
  clickingYesWillOverride: "Clicking 'Yes' will override previous selections.",
  updateGroupName: 'Update Group Name',
  backToBroadcastGroups: 'Back to Broadcast Groups',
  backToBroadcastNotifications: 'Back to Broadcast Notifications',
  message: 'Message',
  pushNotification: 'Push Notification',
  subject: 'Subject',
  createNewNotification: 'Create New Notification',
  createNewBroadcastNotification: 'Create New Broadcast Notification',
  selectANotificationType: 'Select a Notification Type',
  enterEmailData: 'Enter Email Data',
  selectGroup: 'Select Group',
  emailSubject: 'Email Subject',
  emailBody: 'Email Body',
  textMessageBody: 'Text Message Body',
  gmail: 'Gmail',
  facebook: 'Facebook',
  apple: 'Apple',
  writeEmailContent: 'Write your email content here',
  writeMessageContent: 'Write your message content here',
  sendNotification: 'Send Notification',
  resendNotification: 'Resend Notification',
  editBroadcastNotification: 'Edit Broadcast Notification',
  areYouSureWantSendNotification:
    'Are you sure you want to send notification to the selected groups?',
  sentSuccessfully: 'Sent Successfully',
  pleaseSelectNotificationType:
    'Please select a notification type to create a broadcast.',
  enterMessageData: 'Enter Message Data',
  yourSessionHasTimedOut:
    'Your session has timed out. Please log in again to access the application.',
  sessionTimedout: 'Session Timeout',
  sessionCode: 'Session Code',
  createdDate: 'Created Date',
  createdTime: 'Created Time',
  userName: 'User Name',
  module: 'Module',
  workshiftLogs: 'Workshift Logs',
  availabilityLogs: 'Availability Logs',
  bookingLogs: 'Booking Logs',
  performer: 'Performer',
  actionDateTime: 'Action Date | Time',
  cancelBookingRequest: 'Cancel Booking Request',
  updateRateAndAllowance: 'Update Rate And Allowances',
  selectOnePharmacistToMove:
    'Select one pharmacist at a time to move to the incompatible list.',
  pharmacistAvgRating: 'Pharmacist Average Rating',
  userNotFound: 'User Not Found',
  selectSoftware: 'Select Software',
  selectBanner: 'Select Banner',
  selectAction: 'Select Action',
  thisUserIsNotActive: 'Sorry, this user is not active.',
  users: 'Users',
  allUsers: 'All Users',
  timeAboveValidation: 'Please ensure the {0} is set after {1}',
  timeBelowValidation: 'Please ensure the {0} is set before {1}',
  editEmail: 'Edit Email',
  confirmEditEmail: 'Are you sure you want to edit the email?',
  selfService: 'Self Service',
  requestPendingPeriod: 'Request Pending Period',
  noCancellationPeriod: 'No Cancellation Period',
  cancellationFees: 'Cancellation Fees',
  freeCancellationPeriod: 'Free Cancellation Period',
  pharmacistReminderPost: 'Pharmacist Reminder 1 (Post-Approval)',
  pharmacistReminderPre: 'Pharmacist Reminder 2 (Pre-Expiration)',
  hrs: 'Hrs',
  days: 'Days',
  configurations: 'Configurations',
  appointExistingPharmacy: 'Appoint Existing Pharmacy',
  createNewPharmacyAdmin: 'Create New Pharmacy Admin',
  editPharmacyAdmin: 'Edit Pharmacy Admin',
  backToPharmacyAdmin: 'Back to Pharmacy Administrators',
  pharmacyAdministratorCreatedSuccessfully:
    'Pharmacy Administrator Created Successfully',
  pharmacyAdministratorUpdatedSuccessfully:
    'Pharmacy Administrator Updated Successfully',
  deletePharmacyAdmin: 'Delete Pharmacy Admin',
  areYouSureDeletePharmacyAdmin:
    'Are you sure you want to delete this Pharmacy Administrator?',
  pharmacyAppointedSuccessfully: 'Pharmacy admin appointed successfully',
  appoint: 'Appoint',
  appointPharmacyAdmin: 'Appoint Pharmacy Admin',
  appointedPharmacies: 'Appointed Pharmacies',
  backToAppointPharmacy: 'Back to Appoint Pharmacy',
  enterPharmacy: 'Enter Pharmacy',
  pharmacyAdminDescription:
    'You can find all your pharmacies right here. Please select a pharmacy and continue.  You can switch your pharmacy selection anytime.',
  totalContractValue: 'Total Contract Value',
  lastLoggedIn: 'Last Logged In {0} ago',
  contractBreakdown: 'Contract Breakdown',
  allPharmacies: 'All Pharmacies',
  searchAndSelectPharmacies:
    "Search and select pharmacies to move to add to 'My Pharmacies' list.",
  atleastTwoPharmacies: 'Atleast two pharmacies should be added in this list.',
  lastLoginLongTime: 'Last login was a long time ago.',
  away: 'away',
  watchlistReport: 'Watchlist Report',
  addToWatchlist: 'Add to Watchlist',
  removeFromWatchlist: 'Remove from Watchlist',
  areYouSureRemoveWatchlist:
    'Are you sure you want to remove this workshift from watchlist?',
  watchlistedPharmacyWorkshifts: 'Watchlisted Pharmacy Workshifts',
  watchlist: 'Watchlist',
  deleteFromGroup: 'Delete from group',
  createdBy: 'Created By',
  addedToWatchlist: 'Added to watchlist',
  removedFromWatchlist: 'Removed from watchlist',
  switchPharmacy: 'Switch Pharmacy',
  communicationsEmail: 'Communications Email',
  vacation: 'Vacation',
  pleaseSelectDay: 'Please select a day',
  confirmDeleteWorkshifts: 'Are you sure you want to delete these workshifts?',
  confirmDeleteCountWorkshift:
    'Are you sure you want to delete the {0} selected workshifts',
  techDuringWeek: 'Technicians during the week',
  techDuringWeekend: 'Technicians during the weekend',

  vacations: 'Vacations',
  newVacation: 'New Vacation',
  createVacation: 'Create Vacation',
  deleteVacation: 'Delete Vacation',
  areYouSureDeleteVacation: 'Are you sure you want to delete this vacation?',
  vacationCreatedSuccessfully: 'Vacation created successfully',
  updateVacation: 'Update Vacation',

  vacationAvailabilityConflict:
    'There is already an existing availability on this day. Are you sure you want to replace it with a vacation?',
  vacationRequestedConflict:
    'There is already a requested workshift on this day. If you continue, your booking request will be canceled. Are you sure you want to continue?',
  vacationBookedConflict:
    'Sorry, you cannot create a vacation in this time range as you already have a booking.',
  vacationRequestedAndAvailabilityConflict:
    'There is already an existing availability and a booking request in this time range. If you continue, your current booking request or availability will be canceled and replaced with a vacation. If you continue, these would be removed and replaced with a vacation.?',

  multipleVacationAvailabilitiesConflict:
    'There are existing availabilities in this time range. Are you sure you want to replace them with vacations?',
  multipleVacationRequestedConflict:
    'There are already requested workshifts on some of the days. If you continue, your booking requests will be canceled. Are you sure you want to continue?',
  multipleVacationBookedConflict:
    'Vacation cannot be created for this time range due to an existing booking.',

  adminVacationAvailabilitiesConflict:
    'There are existing availabilities for this pharmacist in this time range. Are you sure you want to replace them with vacations?',
  adminVacationBookedConflict:
    'Sorry, Vacation cannot be created for this time range due to an existing booking.',
  adminVacationRequestedConflict:
    "There are already requested workshifts on some of the days. If you continue, the pharmacist's booking requests will be canceled. Are you sure you want to proceed?",
  adminVacationRequestedAndAvailabilityConflict:
    "There is already an existing availability and a booking request in this time range. If you continue, this pharmacist's current booking request or availability will be canceled and replaced with a vacation. Are you sure you want to proceed?",

  createAvailability: 'Create Availability',
  availabilitiesConflict:
    'A vacation is already scheduled during this time range. Creating an availability now will replace the existing vacation. Do you want to proceed?',
  deleteAvailabilityOrVacation: 'Delete Vacation/Availability',
  deleteAvailabilityOrVacationConfirm:
    'Are you sure you want to delete vacations/availabilities for the selected dates ?',
  workshiftRequestConflict:
    'A workshift request exists for these days. Creating a vacation cancels it. Do you wish to continue?',
  workshiftBookedConflict:
    'A workshift is booked for these days. Sorry, this action cannot be performed.',

  multipleVacationsOrAvailabilities: 'Multiple Availabilities/Vacations',
  addVacation: 'Add Vacation',
  multipleAddConfirmation:
    'Do you want to add multiple availabilities or vacations?',
  availabilitiesOrVacations: 'Availabilities/Vacations',
  availabilityAlreadyRequested:
    'The availability you are trying to delete is already requested for another work shift. Are you sure you want to proceed?',
  newFormat: 'New {0}',
  registrationBasedOnActivation: 'Registrations based on activation status',
  newRegistrations: 'New Registrations',
  registrationStatistics: 'Registration Statistics',
  activeUserStatistics: 'Active User Statistics',
  activeUsersBasedOnLogin: 'Active users based on login activity',
  activeUsersBasedOnActions: 'Active users based on actions perfomed',
  workshiftsStatistics: 'Workshifts Statistics',
  workshiftTypes: 'Workshifts (Posted, Requested, and Booked)',
  cancellationRequests: 'Cancellation requests',
  numberOfWorkshift: '# of {0} WS',
  cancellationByUser: 'Cancellation requested by {0}',
  financialStatistics: 'Financial  Statistics',
  financialInsights: 'Financial insights',
  pharmacyBilling: 'Pharmacy Billing',
  pharmacistPayout: 'Pharmacist Payout',
  pharmaplanGrossProfit: 'PharmaPlan Gross Profit',
  averageFinancialInsights: 'Average Financial insights',
  averagePharmacyRate: 'Average Pharmacy Rate',
  averagePharmacistRate: 'Average Pharmacist Rate',
  averageHourlyProfit: 'Average Hourly Profit',
  totalBookedHours: 'Total Booked Hours',
  approvedUsers: 'Approved {0}',
  pharmacistsPendingActivation: 'Pharmacists Pending Activation',
  pharmaciesPendingActivation: 'Pharmacies Pending Activation',
  otherActivities: 'Other Activities',
  pharmaplanStatistics: 'Pharmaplan Statistics',
  dailyReport: 'Daily Report',
  pendingCancellationRequests: 'Pending cancellation requests',
  averageFinancialSummary: 'Average Financial Summary',
  pharmacyRegistrations: 'Pharmacy Registrations',
  pharmacistRegistrations: 'Pharmacist Registrations',
  workshiftPostings: 'Workshift Postings',
  quarter: 'Quarter',
  newUserRegistrations: 'New User - Registrations',
  weekSpaceDays: 'Week Days',
  updateProfile: 'Update Profile',
  actionCannotBePerformed: 'Action cannot be performed',
  expressReport: 'Express Reports',
  chat: 'Chat',
  engaged: 'Engaged',
  assignedToMe: 'Assigned To Me',
  idle: 'Idle',
  closeChat: 'Close Chat',
  areYouSureCloseChat: 'Are you sure you want to close this chat',
};
