import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminCloseChat,
  chatActions,
  ConfirmationTypes,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import { resetDialog, setDialog } from '../../../../reducers/dialogReducer';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Constants } from '../../../../helpers/Constants';
import CustomButton from '../../../common/CustomButton';
import strings from '../../../../localization';

import styles from './styles';

interface IGroupChannelTitle {
  title: string;
  url: string;
}

const GroupChannelTitle: FC<IGroupChannelTitle> = ({ title, url }) => {
  const dispatch = useAppDispatch();
  const success = useAppSelector((state) =>
    successSelector([chatActions.adminCloseChat], state));

  const closeChat = () => {
    dispatch(adminCloseChat(url));
  };

  const showCloseModal = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.closeChat}
            confirmAction={closeChat}
          />
        ),
        showCloseButton: false,
        heading: {
          title: '',
        },
      }),
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(resetStatus([chatActions.adminCloseChat]));
      dispatch(resetDialog());
    }
  }, [success]);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{title}</Typography>
      <CustomButton
        variant={Constants.variant.outlined}
        label={strings.close}
        onClick={showCloseModal}
      />
    </Box>
  );
};

export default GroupChannelTitle;
