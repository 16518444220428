import {
  ColorScheme,
  CreateTypes,
  HelpTypes,
  SocialLoginType,
  TypeOfUser,
} from '../helpers/Constants';
import Routes from '../helpers/Routes';
import { encryptPassword } from '../helpers/utils';
import {
  IChangePasswordParams,
  IContact,
  IDeviceModel,
  IGeneral,
  IPharmacyInfoParams,
  IProfessionalInfo,
  ISmartParamLoginParams,
} from '../models';
import { AppIDType } from '../models/IAppID';
import httpClient from './httpClient';
import { ICreateProfileParams } from './types';
import { getFunc, setFunc } from './httpClient.native';
import { IDeviceDetails } from './MobileRegController';

class UserController {
  authBasePath: string;
  userBasePath: string;
  pharmacyBasePath: string;
  constructor() {
    this.authBasePath = Routes.authentication.authBasePath;
    this.userBasePath = Routes.user.userBasePath;
    this.pharmacyBasePath = Routes.pharmacy.basePath;
  }

  getSecretKey = async (email: string, token: string, appId: AppIDType) =>
    httpClient
      .post(`${this.authBasePath}${Routes.authentication.getSecretKey}`, {
        email,
        token,
        appid: `pp-${appId}`,
      })
      .catch((error) => error);

  login = async (
    email: string | null,
    appId: AppIDType,
    device?: IDeviceModel,
    token?: string,
    loginType?: SocialLoginType,
    password?: string,
    rememberMe?: boolean
  ) => {
    const encryptedPassword = !password ? null : encryptPassword(password);
    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.authentication.login}`,
        {
          loginModel: {
            email,
            loginType,
            token,
            password: encryptedPassword,
            appid: `pp-${appId}`,
            rememberMe,
            device,
          },
        },
        {
          withCredentials: true,
        }
      );

      switch (appId) {
        case 'windows': {
          sessionStorage.setItem('serverDate', resp.data?.serverDate);
          break;
        }

        default:
          break;
      }
      return resp;
    } catch (err) {
      return err;
    }
  };

  refreshToken = async (appId: any) => {
    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.authentication.refreshToken}`
      );

      return resp;
    } catch (err) {
      return err;
    }
  };

  // User/pharmacist/create
  createProfile = async (_params: {
    params: ICreateProfileParams;
    user: TypeOfUser;
  }) => {
    const { params, user } = _params;
    try {
      const resp = await httpClient.post(
        `${this.authBasePath}/${CreateTypes[user as keyof typeof CreateTypes]}`,
        {
          model: params,
        }
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  profile = async () =>
    httpClient.get(`${this.userBasePath}${Routes.user.profile}`);

  profileUtils = async (userType: TypeOfUser) =>
    httpClient.get(`${this.userBasePath}${Routes.user.profile}/${userType}`);

  general = async () =>
    httpClient.get(`${this.userBasePath}${Routes.user.general}`);

  generalUpdate = async (values: IGeneral) =>
    httpClient.put(`${this.userBasePath}${Routes.user.general}`, {
      profileGeneral: {
        ...values,
      },
    });
  contact = async () =>
    httpClient.get(`${this.userBasePath}${Routes.user.contact}`);
  contactUpdate = async (values: IContact) =>
    httpClient.put(`${this.userBasePath}${Routes.user.contact}`, {
      pharmacistcontact: {
        ...values,
      },
    });

  coordinateUpdate = async (values: any) =>
    httpClient.put(
      `${this.userBasePath}${Routes.pharmacy.basePath}${Routes.user.coordinates}`,
      {
        model: {
          ...values,
        },
      }
    );
  pharmacyContactUpdate = async (params: any) =>
    httpClient.put(
      `${this.userBasePath}${Routes.pharmacy.basePath}/${params?.type}`,
      {
        model: {
          ...params?.values,
        },
      }
    );

  software = async () =>
    httpClient.get(`${this.userBasePath}${Routes.user.software}`);

  softwareUpdate = async (values: any) =>
    httpClient.put(`${this.userBasePath}${Routes.user.software}`, {
      profileSoftwares: values,
    });
  proInfo = async () =>
    httpClient.get(
      `${this.userBasePath}${Routes.user.professionalInformation}`
    );
  proInfoUpdate = async (values: IProfessionalInfo) =>
    httpClient.put(
      `${this.userBasePath}${Routes.user.professionalInformation}`,
      {
        profileProfessionalInformation: {
          ...values,
        },
      }
    );

  getAllBannerLogos = async () =>
    httpClient.get(`${this.userBasePath}${Routes.user.allLogos}`);
  getPrimaryContact = async () =>
    httpClient.get(
      `${this.userBasePath}${this.pharmacyBasePath}${Routes.user.primarycontact}`
    );
  getSecondaryContact = async () =>
    httpClient.get(
      `${this.userBasePath}${this.pharmacyBasePath}${Routes.user.secondarycontact}`
    );
  getUserAccountingContact = async () =>
    httpClient.get(
      `${this.userBasePath}${this.pharmacyBasePath}${Routes.user.primarycontact}`
    );
  getAccountingContact = async () =>
    httpClient.get(
      `${this.userBasePath}${this.pharmacyBasePath}${Routes.user.accountingContact}`
    );
  getPharmacyInfo = async () =>
    httpClient.get(
      `${this.userBasePath}${this.pharmacyBasePath}${Routes.user.info}`
    );
  getCoordinates = async () =>
    httpClient.get(
      `${this.userBasePath}${this.pharmacyBasePath}${Routes.user.coordinates}`
    );
  getBannerLogoByLogoID = async (logoId: string) =>
    httpClient.get(`${this.userBasePath}${Routes.user.allLogos}/${logoId}`);

  updatePharmacyInfo = async (params: IPharmacyInfoParams) =>
    httpClient.put(
      `${this.userBasePath}${this.pharmacyBasePath}${Routes.user.info}`,
      {
        model: params,
      }
    );

  changePassword = async ({
    email,
    appId,
    oldPassword,
    newPassword,
  }: IChangePasswordParams) => {
    const encryptedNewPassword = encryptPassword(newPassword);
    const encryptedOldPassword = encryptPassword(oldPassword);
    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.util.changePassword}`,
        {
          email,
          appId: `pp-${appId}`,
          oldPassword: encryptedOldPassword,
          newPassword: encryptedNewPassword,
        }
      );
      return resp;
    } catch (err) {
      return err;
    }
  };
  forgotPassword = async (email: string) => {
    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.util.forgotPassword}`,
        {
          email,
        }
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  resendOtp = async (email: string) => {
    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.util.resendOtp}`,
        {
          email,
        }
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  verifyOtp = async (email: string, otp: string) => {
    const encryptedOtp = encryptPassword(otp);

    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.util.verifyOtp}`,
        {
          email,
          otp: encryptedOtp,
        }
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  resetPassword = async (email: string, password: string) => {
    const encryptedPassword = encryptPassword(password);

    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.util.resetPassword}`,
        {
          email,
          password: encryptedPassword,
        }
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  getFaq = async (typeOfUser: TypeOfUser) => {
    try {
      const resp = await httpClient.get(
        `${this.userBasePath}${Routes.util.help}/${
          HelpTypes[typeOfUser.toLowerCase() as keyof typeof HelpTypes]
        }`
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  getUserName = async () => {
    try {
      const resp = await httpClient.get(
        `${this.authBasePath}${Routes.util.getUser}`
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  getTermsAndConditions = async () => {
    try {
      const resp = await httpClient.get(`${Routes.user.termsAndConditions}`);
      return resp;
    } catch (err) {
      return err;
    }
  };

  getTheme = async () => {
    try {
      const resp = await httpClient.get(
        `${this.userBasePath}${Routes.user.theme}`
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  setTheme = async (theme: ColorScheme) => {
    try {
      const resp = await httpClient.put(
        `${this.userBasePath}${Routes.user.theme}`,
        {
          theme,
        }
      );
      return resp;
    } catch (err) {
      return err;
    }
  };

  doesEmailExist = async (email: string) =>
    httpClient.post(`${this.authBasePath}${Routes.general.emails}`, {
      email,
    });

  deleteUser = async () =>
    httpClient.delete(`${this.userBasePath}${Routes.general.delete}`);

  userConfigurations = async () =>
    httpClient.get(`${this.userBasePath}${Routes.general.configurations}`);

  smartLogin = async (params: ISmartParamLoginParams) => {
    const encryptedHash = encryptPassword(params.userHash);
    const encryptedTotp = encryptPassword(params.totp);

    try {
      const resp = await httpClient.post(
        `${this.authBasePath}${Routes.authentication.smartLogin}`,
        {
          smartLoginModel: {
            totp: encryptedTotp,
            userHash: encryptedHash,
            deviceId: params.deviceId,
          },
        }
      );
      httpClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${resp?.data?.key}`;
      setFunc(resp?.data?.refreshToken);
      return resp;
    } catch (err) {
      return err;
    }
  };

  sendOtp = async (email: string) =>
    httpClient.post(`${this.userBasePath}${Routes.user.sendOtp}`, {
      email,
    });

  authResendOtp = async (email: string) =>
    httpClient.post(`${this.userBasePath}${Routes.util.resendOtp}`, { email });

  authVerifyOtp = async (email: string, otp: string) => {
    const encryptedOtp = encryptPassword(otp);

    const resp = await httpClient.post(
      `${this.userBasePath}${Routes.util.verifyOtp}`,
      {
        email,
        otp: encryptedOtp,
      }
    );

    return resp;
  };

  logout = async () =>
    httpClient.get(`${this.authBasePath}${Routes.user.logout}`, {
      withCredentials: true,
    });

  addEmailPassword = async (email: string, password: string) => {
    const encryptedPassword = encryptPassword(password);

    const resp = await httpClient.post(
      `${this.userBasePath}${Routes.user.setEmail}`,
      { email, password: encryptedPassword }
    );

    return resp;
  };

}

export default new UserController();
