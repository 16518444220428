/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import ChatIcon from '@mui/icons-material/Chat';
import Fab from '@mui/material/Fab';
import { Box } from '@mui/system';
import { Avatar, ClickAwayListener, Tooltip, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  chatActions,
  clientUnreadCount,
  clientUnreadCountSelector,
  createGroupChannel,
  getChannelUrl,
  getTypeOfUser,
  resetStatus,
  successSelector,
  userIdSelector,
} from '@pharmaplan/common';
import { Message } from '@sendbird/uikit-react/GroupChannel/components/Message';
import MessageContent from '@sendbird/uikit-react/ui/MessageContent';
import GroupChannelHeader from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

const successAction = chatActions.createGroupChannel;

const FloatingChat = () => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);
  const count = useAppSelector(clientUnreadCountSelector);
  const { pathname } = useLocation();

  const userType = useAppSelector(getTypeOfUser);
  const userId = useAppSelector(userIdSelector);
  const channelUrl = useAppSelector(getChannelUrl);

  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const requestSupport = () => {
    dispatch(createGroupChannel(userType.toLowerCase()));
  };

  useEffect(() => {
    if (success) {
      setShow(true);
      dispatch(resetStatus([successAction]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(clientUnreadCount(userType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShow(false);
      }}
    >
      <Box>
        <Box sx={{ position: 'fixed', bottom: 80, right: 80 }}>
          {show && (
            <Box sx={{ height: 488, width: 400 }}>
              <GroupChannel
                renderChannelHeader={(props) =>
                  (
                    <GroupChannelHeader
                      {...props}
                      renderRight={() =>
                        <div />}
                      renderLeft={() =>
                        <div />}
                    />
                  )}
                renderMessage={(props) =>
                  (
                    <Message
                    // eslint-disable-next-line no-underscore-dangle, react/jsx-props-no-spreading
                      {...props}
                      renderMessageContent={(inProps) => {
                        console.log({ inProps }, inProps?.userId);
                        return (
                          <MessageContent
                          // eslint-disable-next-line no-underscore-dangle, react/jsx-props-no-spreading
                            {...inProps}
                            renderSenderProfile={
                            (props.message as any)?.sender?.userId === userId
                              ? undefined
                              : (ininProps) =>
                                (
                                  <Box
                                    sx={{
                                      display: 'inline-flex',
                                      minWidth: '40px',
                                      position: 'relative',
                                    }}
                                  >
                                    <Avatar
                                      imgProps={{
                                        sx: {
                                          objectFit: 'contain',
                                        },
                                      }}
                                      sx={{
                                        height: 28,
                                        width: 28,
                                        bottom: '2px',
                                        border: '1px solid',
                                        borderColor: 'common.border',
                                        left: '0',
                                        position: 'absolute',
                                        objectFit: 'contain',
                                      }}
                                      src="https://app.pharmaplan.ca/logo192.png"
                                    />
                                  </Box>
                                )
                          }
                          />
                        );
                      }}
                    />
                  )}
                channelUrl={channelUrl}
              />
            </Box>
          )}
        </Box>
        <Fab
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          color="primary"
          aria-label="chat"
          onClick={requestSupport}
        >
          {!!count && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                backgroundColor: '#d32f2f',
                justifyContent: 'center',
                alignItems: 'center',
                height: 15,
                width: 15,
                borderRadius: 8,
              }}
            >
              <Typography
                sx={{ color: 'white', fontWeight: '500', fontSize: 10 }}
              >
                {count}
              </Typography>
            </Box>
          )}
          <Tooltip title="Chat with us">
            {show ? <Close /> : <ChatIcon />}
          </Tooltip>
        </Fab>
      </Box>
    </ClickAwayListener>
  );
};

export default FloatingChat;
