import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const size = 40;
const dotSize = 15;

const styles = {
  channelItemContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ThemeConstants.common.lightBackgroundColor,
    borderBottom: '1px solid',
    borderColor: ThemeConstants.common.border,
  },
  avatar: {
    borderRadius: size / 2,
    height: size,
    width: size,
    margin: 5,
    objectFit: 'contain',
    border: '1px solid',
    borderColor: '#E1E4EF',
  },
  channelInfoContainer: {
    flex: 1,
    m: 1,
  },
  selectedChannel: {
    borderLeft: '5px solid',
    borderLeftColor: 'primary.main',
  },
  channelTitle: {
    fontSize: 16,
  },
  engaged: {
    backgroundColor: 'common.error',
  },
  available: {
    backgroundColor: 'pharmacist.availability',
  },
  assignedToMe: {
    backgroundColor: 'primary.main',
  },
  away: {
    backgroundColor: 'chatColors.orange500',
  },
  dot: {
    position: 'absolute',
    bottom: 9,
    right: 0,
    height: dotSize,
    width: dotSize,
    borderRadius: dotSize / 2,
  },
  lastMessage: {
    color: '#969FA8',
    fontSize: 13,
  },
  statusContainer: {
    position: 'relative',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
