import { addGroupChannelMetadata } from '@pharmaplan/common';
import { useAppDispatch } from '../../useAppDispatch';

const ChatManager = () => {
  const dispatch = useAppDispatch();

  const assignToCurrentUser = (userId: string, channel: string) => {
    console.log(userId, channel);
    dispatch(
      addGroupChannelMetadata({
        key: 'users',
        value: userId,
        channel,
      }),
    );
  };
  return { assignToCurrentUser };
};

export default ChatManager;
