const Routes = {
  authentication: {
    authBasePath: '/authentication',
    authenticateUser: '/auth',
    login: '/login',
    smartLogin: '/smart-login',
    refreshToken: '/refreshToken',
    getSecretKey: '/getsecretkey',
  },
  pharmacyAdmin: {
    pharmacyAdminBasePath: '/pharmacyadmin',
    pharmacies: '/pharmacies',
    admins: '/admins',
  },
  chat: {
    sendbirdBasePath: '/sendbird',
    channel: '/channel',
    channels: '/channels',
    chats: '/chats',
    unreadCount: '/messages/unread/count',
    metadata: '/metadata',
  },

  admin: {
    adminBasePath: '/admin',
    allUsers: '/all-users',
    pharmacyAdmins: '/pharmacy-admins',
    pharmacyAdminList: '/pharmacy-admins-list',
    calendar: '/calendar',
    map: '/map',
    active: '/active',
    cancellation: '/cancellation',
    replacements: '/replacements',
    workshifts: '/workshifts',
    posted: '/posted',
    requested: '/requested',
    booked: '/booked',
    users: '/users',
    matchingAvailabilities: '/matching-availabilities',
    notMatchingAvailabilities: '/notmatching-availabilities',
    bookAvailability: '/book-availablity',
    pharmacies: '/pharmacies',
    pharmacists: '/pharmacists',
    availabilities: '/availabilities',
    expressBooking: '/express-booking',
    validateExpressBooking: '/validate-express-booking',
    pendingActivations: '/pending-activations',
    pendingCancellations: '/pending-cancellations',
    takeOwnership: '/take-ownership',
    activate: '/activate',
    pictures: '/pictures',
    resumes: '/resumes',
    deletedUsers: '/deleted-users',
    softwares: '/softwares',
    pharmacistCapacities: '/pharmacist-capacities',
    contactPreferences: '/contact-preferences',
    pharmaplan: '/pharmaplan',
    salesforceAdmins: '/salesforce-admins',
    pharmacyChain: '/pharmacy-chains',
    pharmacistRegions: '/pharmacist-regions',
    pharmacistResumes: '/pharmacist-resumes',
    cancelledBookings: '/cancelled-bookings',
    rejectedCancellations: '/rejected-cancellations',
    allNotes: '/allnotes',
    showAvailabities: '/show-availabilities',
    matchingWorkshifts: '/matching-workshifts',
    hide: '/hide',
    deactivate: '/deactivate',
    pharmacistPictures: '/pharmacist-pictures',
    pendingActivationsCount: '/pending-activations/count',
    admins: '/admins',
    hidden: '/hidden',
    inactive: '/inactive',
    published: '/published',
    permanentDelete: '/permanent-delete',
    deleted: '/deleted',
    disapprove: '/disapprove',
    approve: '/approve',
    profile: '/profile',
    detailsSearch: '/detail-search',
    ratingDetails: '/rating-details',
    broadcasts: '/broadcasts',
    groups: '/groups',
    logs: '/logs',
    activities: '/activities',
    me: '/me',
    picture: '/picture',
    appoint: '/appoint',
    appointed: '/appointed',
    watchlists: '/watchlists',
    selectedUsers: '/selected-users',
    statistics: '/statistics',
    financial: '/financial',
    daily: '/daily',
  },
  user: {
    userBasePath: '/User',
    contact: '/contact',
    sendOtp: '/sendotp',
    setEmail: '/setemailpassword',
    software: '/profilesoftware',
    general: '/profilegeneral',
    primarycontact: '/primarycontact',
    secondarycontact: '/secondarycontact',
    info: '/info',
    accountingContact: '/accountingcontact',
    coordinates: '/coordinates',
    professionalInformation: '/profileprofessionalinformation',
    profile: '/profile',
    allLogos: '/banners/logos',
    termsAndConditions: '/system/document/SignupTermsConditons',
    theme: '/theme',
    logout: '/logout',
    prelogout: '/pre-logout',
  },
  pharmacist: {
    pharmacistBasePath: '/Pharmacist',
    pharmacist: '/pharmacist',
    summary: '/summary',
    posted: '/posted',
    picture: '/picture',
    fileupload: '/fileupload',
    resume: '/resume',
    calendar: 'calendar',
    pharmacistCalendar: '/pharmacistcalendar',
    reports: '/reports',
    map: '/map',
    replacements: '/replacements',
    history: '/history',
    addFavourite: '/addfavorite',
    removeFavourite: '/removefavorite',
    create: '/create',
    availabilites: {
      dateRangeAvailability: '/availabilities/',
      create: '/availabilities/create',
      update: '/availabilities/update',
      perMonth: '/availabilities/availabilitiespermonth',
      delete: '/availabilities/delete',
    },
    regions: {
      region: '/regions',
      createRegion: '/regions/create',
    },
    bookings: {
      dateRangeBookings: '/bookings/',
      preBooking: '/bookings/requests/pre-submit',
      perMonth: '/bookings/bookingspermonth',
      pendingBookings: '/bookings/pending',
      submitRequest: '/bookings/requests/submit',
      cancelRequest: '/bookings/cancellations/request',
      deleteRequest: '/bookings/requests/delete',
    },
    workshifts: {
      perMonth: '/workshifts/availablepermonth',
      availableWorkshiftsDateRange: '/workshifts/available/',
    },
    notes: {
      createNote: '/pharmacy/notes/create',
      allNotes: '/pharmacy/notes',
      updateNote: '/pharmacy/notes/update',
      deleteNote: '/pharmacy/notes/delete',
    },
    counterOffer: '/counteroffer',
    income: '/income',
    incomeDetails: '/income/details',
    watchlist: '/watchlist',
    vacations: '/vacations',
  },
  pharmacy: {
    basePath: '/pharmacy',
    calendar: '/calendar',
    workshifts: '/workshifts',
    requestedBookings: '/requested',
    replacements: '/replacements',
    history: '/history',
    workshiftData: '/workshiftdata',
    notes: '/notes',
    approve: '/approve',
    ignore: '/ignore',
    reject: '/reject',
    favorite: '/favorite',
    ratingItems: '/ratingitems',
    rate: '/rate',
    rating: '/rating',
    incompatibleMatches: '/incompatible-matches',
    updateTime: '/updatetime',
    reportIssue: '/reportissue',
    preApprove: '/pre-approve',
  },
  mobileRegistration: {
    registrationBasePath: '/Registration',
    register: '/mobile',
    updateDeviceToken: '/devicetoken/update/',
    getByDeviceID: '/getbydeviceid/',
    getByRegId: '/getbyregid/',
  },
  util: {
    getContactInfo: '/Contact/info',
    changePassword: '/changepassword',
    forgotPassword: '/forgotpassword',
    resetPassword: '/resetpassword',
    getPublicKey: '/get-public-key',
    resendOtp: '/resendotp',
    verifyOtp: '/verifyotp',
    help: '/help',
    registerSmartLogin: 'register-smart-login',
    deRegisterSmartLogin: 'de-register-smart-login',
    getUser: '/getusername',
  },
  notifications: {
    basePath: '/system',
    notification: '/notification',
    notifications: '/notifications',
    count: '/count',
    markAsRead: '/mark-read',
  },
  general: {
    filters: '/filters',
    history: '/history',
    workshift: '/workshift',
    settings: '/settings',
    languages: '/languages',
    configurations: '/configurations',
    reports: '/reports',
    notes: '/notes',
    fileupload: '/fileupload',
    replacements: '/replacements',
    cancelRequest: '/bookings/cancellations/request',
    getPDF: '/pdf',
    events: '/events',
    multipleEvents: 'availabilities/workshifts',
    get: '/get',
    exists: '/exists',
    emails: '/emails',
    submit: '/submit',
    availabilities: '/availabilities',
    delete: '/delete',
    system: '/system',
    details: '/details',
    bookings: '/bookings',
    cancel: '/cancel',
    activate: '/activate',
    show: '/show',
    deleted: '/deleted',
    allWorkshifts: '/all-workshifts',
    list: '/list',
    workshifts: '/workshifts',
    preSubmit: '/pre-submit',
  },
};

export default Routes;
